<template src='./bulkpromotion.html'>
</template>
<style scoped src='./bulkpromotion.css'>

</style>
<script>
import promotionConstants from '@/constants/PromotionConstants.vue'
import Loading from 'vue-loading-overlay'
import PromotionService from '@/services/PromotionService'
import applicationConstants from '@/constants/AppConstants.vue'
import { required, requiredIf, maxValue} from 'vuelidate/lib/validators'
import modal from '@/components/modal/notification/modal'

export default {
    data () {
        return {
            promo : {
                expirationDate : '',
                nomination : '',
                isFistTransation : false,
                isGreaterThenFee : false,
                name : '',
                promoType : 'True',
                currency : 'CAD',
                issuedToEmail : '',
                additionalInfo : '',
                reasponForIssue: '',
                promoCode : ''
            },
            promoTypeList: promotionConstants.promoTypeList,
            codeStartWith : '',
            currencyList: promotionConstants.currencyList,
            reasonForIssueList: promotionConstants.reasonForIssueList,
            hideAdditionalInfo: true,
            loaderVisible: false,
            errorMessages: applicationConstants.formsMessages,
            columns: ['No', 'CustomerEmail', 'PromoCode', 'Error'],
            options : {
                headings : {
                    No            : 'SI.No',
                    CustomerEmail : 'Customer Email',
                    PromoCode     : 'Promo Code'
                }
            },
            resultData : [],
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            }
        }
    },
    methods : {
        nominationRestriction ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
            if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.promo.nomination.indexOf('.') != -1)) {
                $event.preventDefault();
            }
            if (this.promo.nomination !== null && this.promo.nomination.indexOf('.') > -1  && (this.promo.nomination.split('.')[1].length > 1)) {
                $event.preventDefault();
            }
        },
        reasonForIssueListChangeEvent (event) {
            // this.reasonForIssue = event
            if (event === 'Other'){
                this.hideAdditionalInfo = false
            } else {
                this.hideAdditionalInfo = true
                this.additionalInfo = ''
            }
        },
        async onSubmit () {           
            this.$v.promo.$touch()
            let reason = this.promo.reasponForIssue 
            if (this.promo.reasponForIssue === 'Other') {
                reason += ' - ' + this.promo.additionalInfo
            } 
            if (this.$v.$invalid) {
                return
            }
            this.loaderVisible = true
            let emailList = this.promo.issuedToEmail.split('\n')
            let isValidEmails = true
            const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            emailList.forEach(email => {
                if (!((pattern).test(email))) {
                    isValidEmails = false
                }
            })
            if (isValidEmails) {
                if(Array.isArray(emailList)){
                    for (let index = 0; index < emailList.length; index++) {
                        await this.AddPromotion(emailList[index], reason, index)                    
                    }
                } else {
                    await this.AddPromotion(this.promo.issuedToEmail, reason)
                }
            } else {
                this.showAlert(null, 'invalid email')
            }   
            this.loaderVisible = false
        },
        AddPromotion(email, reason, index) {
            PromotionService.methods.UpdatePromotionDetails({
                'OperationType'   : 'A',
                'PromoCodeDetails':  {
                    'Code'            : this.promo.promoCode,                    
                    'Currency'       : this.promo.currency,
                    'description'     : '',
                    'ExpirationDate' : this.promo.expirationDate,
                    'FirstTransactionOnly' : this.promo.isFistTransation ? "True" : "False" ,
                    'IssuedTo'             : email,
                    'Nomination'           : this.promo.nomination,
                    'IsIndividualCode'     : this.promo.promoType,
                    'ReasonIssued'         : reason,
                    'AllowGreaterThanFee'  : this.promo.isGreaterThenFee ? "True" : "False" ,
                    'Name'                 : this.promo.name,
                    'Issue'                : (parseInt(this.codeStartWith) + parseInt(index)).toString()
                }
            })
            .then( res => {
                this.resultData.push({
                    'No': index + 1,
                    'CustomerEmail' : email,
                    'PromoCode' : res.csManagePromoCodeResponse.PromoCodeDetails.Code._text + '-' + res.csManagePromoCodeResponse.PromoCodeDetails.Issue._text,
                    'Error'   : res.csManagePromoCodeResponse?.ErrorDescription._text
                })   
            })
            .catch(error => {
                this.resultData.push({
                    'No': index + 1,
                    'CustomerEmail' : email,
                    'PromoCode' : '',
                    'Error'   : error
                })         
            })
        },
        clearAll () {
            this.promo.expirationDate = ''
            this.promo.nomination = ''
            this.promo.isFistTransation = false
            this.promo.isGreaterThenFee = false
            this.promo.issueId = ''
            this.promo.name = ''
            this.promo.issuedToEmail = ''
            this.promo.additionalInfo = ''
            this.promo.reasponForIssue= ''
            this.codeStartWith = ''
            this.$v.$reset()
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        }
    },
    components : {
        Loading, modal       
    },
    computed : {
        minDate () {
           return new Date()
        }
    },
    validations : {
         promo : {
            name : { required },
            promoType : { required },       
            nomination : { required },
            expirationDate : { required },
            issuedToEmail : { required },      
            currency : { required },
            reasponForIssue : { required },
            additionalInfo : { 
                required : requiredIf(function () {
                    return !this.hideAdditionalInfo
                }) },
            promoCode : { required }
        },
        codeStartWith : {
            required : requiredIf(function () {
                return this.codeStartWith.length > 0
            }),
            maxValue : maxValue(99)
        } 
    }

}
</script>